// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-pihome-js": () => import("./../../../src/pages/about-pihome.js" /* webpackChunkName: "component---src-pages-about-pihome-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/comingSoon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

