import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faLink,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faChevronRight,
  faChevronLeft,
  faLaptopCode,
  faLock,
  faStream,
  faCheck,
  faPuzzlePiece,
  faCogs,
  faSearch,
  faPaperPlane,
  faMobileAlt,
  faBell,
  faChartArea,
  faShareAlt,
  faSyncAlt,
  faUser,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faChartPie,
  faFlag,
  faChartLine,
  faPlay,
  faLayerGroup
} from "@fortawesome/free-solid-svg-icons"

import {
  faFacebookF,
  faGoogle,
  faInstagram,
  faTwitter,
  faFacebookSquare
  // faYoutube,
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faLayerGroup,
  faLink,
  faMobileAlt,
  faCalendarAlt,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faAngleDown,
  faLaptopCode,
  faLock,
  faStream,
  faInstagram,
  faFacebookF,
  faGoogle,
  faCheck,
  faPuzzlePiece,
  faCogs,
  faSearch,
  faPaperPlane,
  faBell,
  faChartArea,
  faShareAlt,
  faSyncAlt,
  faAngleLeft,
  faAngleRight,
  faTwitter,
  faFacebookSquare,
  faUser,
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faChartPie,
  faFlag,
  faChartLine,
  faPlay
)
